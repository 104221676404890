<template>
  <div class="p-0">
    <TTabs :tabs="tabs" @change-tab="changeTab" :activeTab="$route.query.tab">
      <template #detail>
        <Contract :showBtn="false"/>
      </template>
      <template #order_list>
        <OrderList :contractId="id" :showBtn="false" />
      </template>
      <template #order_items_list>
        <OrderItemsList :items="contract.items" />
      </template>
      <template #logs>
        <SCardLog :logableId="id" />
      </template>
      <template #transactions>
        <SCardTransaction :objectableId="id" />
      </template>
    </TTabs>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
import Contract from "../../../sale/views/contract/components/Contract.vue";
import OrderList from "../../../sale/views/contract/components/OrderList.vue";
import OrderItemsList from "../../../sale/views/contract/components/OrderItemsList.vue";

export default {
  components: {
    Contract,
    OrderList,
    OrderItemsList,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Contract detail", icon: "cis-library-books" },
        {
          key: "order_list",
          name: "Order list",
          icon: "cisListRich",
        },
        {
          key: "order_items_list",
          name: "Goods",
          icon: "cisBox",
        },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
        {
          key: "logs",
          name: "Logs",
          icon: "cis-note",
        },
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store
        .dispatch("order.contracts.detail.fetch", this.id)
        .catch(() => {
          this.$router.push("/sale/contracts/contracts");
        });
    }
    this.$store.dispatch("order.order_types.fetch.if-first-time");
  },
  destroyed() {
    this.$store.commit("order.contracts.detail.purge");
  },
  computed: {
    ...mapGetters({
      contract: "order.contracts.detail",
    }),
    listOrder() {
      return this.contract?.orders?.map((x) => x.id);
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
    fetchContract() {
      this.$store.dispatch("order.contracts.detail.refresh");
    },
  },
};
</script>
  